import React from 'react';
import instg from '../assets/instg.png'

const FloatingInstagramIcon = () => {
    const instagramIconUrl = instg
    const instagramProfileUrl = 'https://www.instagram.com/_pajisjesportive/'; // Replace with your Instagram username

    const iconContainerStyle = {
        position: 'fixed',
        bottom: '150px',
        right: '-1px',
        zIndex: 1000,
    };

    const iconStyle = {
        width: '45px', // Adjust the size as needed
        height: '45px',
        borderRadius: '10%',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease-in-out',
    };

    return (
        <div style={iconContainerStyle}>
            <a href={instagramProfileUrl} target="_blank" rel="noopener noreferrer">
                <img src={instagramIconUrl} alt="Instagram" style={iconStyle} />
            </a>
        </div>
    );
};

export default FloatingInstagramIcon;

