import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import doni1 from '../assets/doni1.jpg'
// import ddd from '../assets/ddd.webp'
// import itl from '../assets/itl.jpeg'


// import NavDropdown from "react-bootstrap/NavDropdown";
// import { useEffect, useState } from "react";

function Navbar1() {
  // const [sticky, setSticky] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setSticky(window.scrollY > 200);
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // });

  // useEffect(() => {
  //   document.getElementById("navbar").scrollIntoView();
  // }, []);

  return (
    <Navbar
      id="navbar"
      // className={`${sticky ? "sticky" : ""}`}
      collapseOnSelect
      expand="lg"
      // bg="dark"
      variant="dark"
      style={{
        // background: "#0693e3",
        background: "#023047", 
        
        // background: '',
        // background: 'linear-gradient(to right, #3056ff 0%,#3056ff 32%,#ff3033 33%,#ff282c 66%,#2989d8 67%,#2989d8 67%,#7db9e8 100%)',
        // background: 'linear-gradient(to right, #008b8b , #a9a9a9)'
        // boxShadow: "0px 5px 18px rgba(0,0,0,0.6)",        
        // top: "0px",
        // zIndex: "300",
        // position: "sticky",
      }}
    >
      <Container>
      <img src={doni1} style={{width: '40px', height: '40px', marginRight: '35px', borderRadius: '50%' }} alt='sss'></img>
        <Navbar.Brand href="#" style={{color: 'violet'}}>Pajisje Sportive</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <a href="#produktet"  style={{marginTop: '2px', marginRight: '15px', fontSize: '17px', textDecoration: 'none', color: 'white'}}>Produktet</a>
            <a href="#bestseller"  style={{marginTop: '2px', marginRight: '15px', fontSize: '17px', textDecoration: 'none', color: 'white'}}>BestSeller</a>
            <a href="#historia"  style={{marginTop: '2px', marginRight: '1px', fontSize: '17px', textDecoration: 'none', color: 'white'}}>Historia jone </a>
          </Nav>
          <Nav>
            <a href="#posta" style={{marginTop: '7px', marginRight: '5px'}}>
              
              Posta
            </a>
            {/* <img src={itl} alt='italy' style={{width: '45px', 
            height: '45px',
            opacity: '0.7',
            filter: 'blur(0.3px)', 
            borderRadius: '30%'}} ></img> */}
            <Nav.Link eventKey={2} href="tel:+xxxxxxxxxxx"  style= {{}}>
              Kontaktoni:  
            </Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbar1;