// import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
// import Layout from './components/Layout';

function App() {
  return (
    <div>
        <Home />
    </div>
  );
}

export default App;
