// import React from "react";

// import "./product-card.css";

// import { Link } from "react-router-dom";

// import {Provider, useDispatch } from "react-redux";
// import { cartActions } from "./cartSlice";

// const ProductCard = (props) => {
//   const { id, title, image01, price } = props.item;
//   const dispatch = useDispatch();

//   const addToCart = () => {
//     dispatch(
//       cartActions.addItem({
//         id,
//         title,
//         image01,
//         price,
//       })
//     );
//   };

//   return (
//     <Provider>
//     <div className="product__item">
//       <div className="product__img">
//         <img src={image01} alt="product-img" className="w-50" />
//       </div>

//       <div className="product__content">
//         <h5>
//           <Link to={`/foods/${id}`}>{title}</Link>
//         </h5>
//         <div className=" d-flex align-items-center justify-content-between ">
//           <span className="product__price">${price}</span>
//           <button className="addTOCart__btn" onClick={addToCart}>
//             Add to Cart
//           </button>
//         </div>
//       </div>
//     </div>
//     </Provider>
//   );
// };

// export default ProductCard;


import React from "react";
import "./product-card.css";
import { Link } from "react-router-dom";
// import { useDispatch } from "react-redux"; 
// Remove Provider import
// import { cartActions } from "./cartSlice";

import instg from '../assets/instg.png'

const ProductCard = (props) => {
  const { id, title, image01, price } = props.item;
  // const dispatch = useDispatch();

  // const addToCart = () => {
  //   dispatch(
  //     cartActions.addItem({
  //       id,
  //       title,
  //       image01,
  //       price,
  //     })
  //   );
  // };

  return (
    <div className="product__item">
      <div className="product__img">
        <img src={image01} alt="foto" className="w-50" />
      </div>

      <div className="product__content">
        <h5>
          <Link to={`/foods/${id}`}>{title}</Link>
        </h5>
        <div className="d-flex align-items-center justify-content-between">
          <span className="product__price">${price}</span>
          <button className="addTOCart__btn">
          {/* onClick={addToCart} */}
            <a href="https://www.instagram.com/_pajisjesportive/">
            <img src={instg} alt='' style={{width: 25, height: 25, border: 0,}}>
            </img>
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;

