import React from 'react';
import './Kjoqereshqet.css';
import w1 from '../assets/fotoreviews/w1.jpeg';
import w2 from '../assets/fotoreviews/w2.jpeg';
import w3 from '../assets/fotoreviews/w3.jpeg';
import w4 from '../assets/fotoreviews/w4.jpeg';
import w5 from '../assets/fotoreviews/w5.jpeg';
import w6 from '../assets/fotoreviews/w6.jpeg';
import w7 from '../assets/fotoreviews/w7.jpeg';
import w8 from '../assets/fotoreviews/w8.jpeg';
import w9 from '../assets/fotoreviews/w9.jpeg';
import w10 from '../assets/fotoreviews/w10.jpeg';
import w11 from '../assets/fotoreviews/w11.jpeg';
import w12 from '../assets/fotoreviews/w12.jpeg';
import w13 from '../assets/fotoreviews/w13.jpeg';
import w14 from '../assets/fotoreviews/w14.jpeg';
import w15 from '../assets/fotoreviews/w15.jpeg';
import w16 from '../assets/fotoreviews/w16.jpeg';
import w17 from '../assets/fotoreviews/w17.jpeg';
import w18 from '../assets/fotoreviews/w18.jpeg';
import w19 from '../assets/fotoreviews/w19.jpeg';
import w20 from '../assets/fotoreviews/w20.jpeg';
import w21 from '../assets/fotoreviews/w21.jpeg';
import w22 from '../assets/fotoreviews/w22.jpeg';
import w23 from '../assets/fotoreviews/w23.jpeg';
import w24 from '../assets/fotoreviews/w24.jpeg';
import w25 from '../assets/fotoreviews/w25.jpeg';
import w26 from '../assets/fotoreviews/w26.jpeg';
import w27 from '../assets/fotoreviews/w27.jpeg';
import w28 from '../assets/fotoreviews/w28.jpeg';
import w29 from '../assets/fotoreviews/w29.jpeg';
import w30 from '../assets/fotoreviews/w30.jpeg';
import w31 from '../assets/fotoreviews/w31.jpeg';
import w32 from '../assets/fotoreviews/w32.jpeg';
import w33 from '../assets/fotoreviews/w33.jpeg';
import w34 from '../assets/fotoreviews/w34.jpeg';
import w35 from '../assets/fotoreviews/w35.jpeg';
import w36 from '../assets/fotoreviews/w36.jpeg';
import w37 from '../assets/fotoreviews/w37.jpeg';
import w38 from '../assets/fotoreviews/w38.jpeg';
import w39 from '../assets/fotoreviews/w39.jpeg';
import w40 from '../assets/fotoreviews/w40.jpeg';
import w41 from '../assets/fotoreviews/w41.jpeg';
import w42 from '../assets/fotoreviews/w42.jpeg';
import w43 from '../assets/fotoreviews/w43.jpeg';
import w44 from '../assets/fotoreviews/w44.jpeg';
import w45 from '../assets/fotoreviews/w45.jpeg';
import w46 from '../assets/fotoreviews/w46.jpeg';
import w47 from '../assets/fotoreviews/w47.jpeg';
import w48 from '../assets/fotoreviews/w48.jpeg';
import w49 from '../assets/fotoreviews/w49.jpeg';
import w50 from '../assets/fotoreviews/w50.jpeg';
import w51 from '../assets/fotoreviews/w51.jpeg';



const TAGS_WITH_IMAGES = {
  Klient1: {
    imageUrl: w1,
    description: 'Klient 1',
  },
  Klient2: {
    imageUrl: w2,
    description: 'Klient 2',
  },
  Klient3: {
    imageUrl: w3,
    description: 'Klient 3',
  },
  Klient4: {
    imageUrl: w4,
    description: 'Klient 4',
  },

  Klient5: {
    imageUrl: w5,
    description: 'Klient 5',
  },
  Klient6: {
    imageUrl: w6,
    description: 'Klient 6',
  },
  Klient7: {
    imageUrl: w7,
    description: 'Klient 7',
  },
  Klient8: {
    imageUrl: w8,
    description: 'Klient 8',
  },
  Klient9: {
    imageUrl: w9,
    description: 'Klient 9',
  },
  Klient10: {
    imageUrl: w10,
    description: 'Klient 10',
  },
  Klient11: {
    imageUrl: w11,
    description: 'Klient 11',
  },
  Klient12: {
    imageUrl: w12,
    description: 'Klient 12',
  },
  Klient13: {
    imageUrl: w13,
    description: 'Klient 13',
  },
  Klient14: {
    imageUrl: w14,
    description: 'Klient 14',
  },
  Klient15: {
    imageUrl: w15,
    description: 'Klient 15',
  },
  Klient16: {
    imageUrl: w16,
    description: 'Klient 16',
  },
  Klient17: {
    imageUrl: w17,
    description: 'Klient 17',
  },
  Klient18: {
    imageUrl: w18,
    description: 'Klient 18 ',
  },
  Klient19: {
    imageUrl: w19,
    description: 'Klient 19',
  },
  Klient20: {
    imageUrl: w20,
    description: 'Klient 20',
  },
  Klient21: {
    imageUrl: w21,
    description: 'Klient 21',
  },
  Klient22: {
    imageUrl: w22,
    description: 'Klient 22',
  },
  Klient23: {
    imageUrl: w23,
    description: 'Klient 23',
  },
  Klient24: {
    imageUrl: w24,
    description: 'Klient 24',
  },
  Klient25: {
    imageUrl: w25,
    description: 'Klient 25',
  },
  Klient26: {
    imageUrl: w26,
    description: 'Klient 26',
  },
  Klient27: {
    imageUrl: w27,
    description: 'Klient 27',
  },
  Klient28: {
    imageUrl: w28,
    description: 'Klient 28',
  },
  Klient29: {
    imageUrl: w29,
    description: 'Klient 29',
  },
  Klient30: {
    imageUrl: w30,
    description: 'Klient 30',
  },
  Klient31: {
    imageUrl: w31,
    description: 'Klient 31',
  },
  Klient32: {
    imageUrl: w32,
    description: 'Klient 32',
  },
  Klient33: {
    imageUrl: w33,
    description: 'Klient 33',
  },
  Klient34: {
    imageUrl: w34,
    description: 'Klient 34',
  },
  Klient35: {
    imageUrl: w35,
    description: 'Klient 35',
  },
  Klient36: {
    imageUrl: w36,
    description: 'Klient 36',
  },
  Klient37: {
    imageUrl: w37,
    description: 'Klient 37',
  },
  Klient38: {
    imageUrl: w38,
    description: 'Klient 38',
  },
  Klient39: {
    imageUrl: w39,
    description: 'Klient 39',
  },
  Klient40: {
    imageUrl: w40,
    description: 'Klient 40',
  },
  Klient41: {
    imageUrl: w41,
    description: 'Klient 41',
  },
  Klient42: {
    imageUrl: w42,
    description: 'Klient 42',
  },
  Klient43: {
    imageUrl: w43,
    description: 'Klient 43',
  },
  Klient44: {
    imageUrl: w44,
    description: 'Klient 44',
  },
  Klient45: {
    imageUrl: w45,
    description: 'Klient 45',
  },
  Klient46: {
    imageUrl: w46,
    description: 'Klient 46',
  },
  Klient47: {
    imageUrl: w47,
    description: 'Klient 47',
  },
  Klient48: {
    imageUrl: w48,
    description: 'Klient 48',
  },
  Klient49: {
    imageUrl: w49,
    description: 'Klient 49',
  }, 
  Klient50: {
    imageUrl: w50,
    description: 'Klient 50',
  },
  Klient51: {
    imageUrl: w51,
    description: 'Klient 51',
  },
  Klient68: {
    imageUrl: w11,
    description: 'Klient 68',
  }, 
  Klient79: {
    imageUrl: w13,
    description: 'Klient 79',
  },
  Klient111: {
    imageUrl: w1,
    description: 'Klient 111',
  },
  // Add more tag-image-description pairs as needed
};

const DURATION = 35000;
const ROWS = 1;
const TAGS_PER_ROW = 8;

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const shuffle = (arr) => [...arr].sort(() => 0.5 - Math.random());
// const shuffle = (arr) => [...arr].sort();

const InfiniteLoopSlider = ({ children, duration, reverse = false }) => (
  <div className='loop-slider' style={{
    '--duration': `${duration}ms`,
    '--direction': reverse ? 'reverse' : 'normal'
  }}>
    <div className='inner'>
      {children}
      {children}
    </div>
  </div>
);

const Tag = ({ text }) => (
  <div className='tag'>
    {/* <span>#</span> {text} */}
    {TAGS_WITH_IMAGES[text] && (
      <div>
        <img src={TAGS_WITH_IMAGES[text].imageUrl} alt={text} className="tag-image" />
        {/* <span>#</span>  */}
        {/* {text} */}
        <p>{TAGS_WITH_IMAGES[text].description}</p>
      </div>
    )}
  </div>
);

const Section5 = () => (
  <div className='app' style={{ marginBottom: '35px' }}>
    <header>
      <h2 style={{fontFamily: 'sans-serif', marginTop: '70px', marginBottom: '30px'}}>Mbi 800 Review pozitive</h2>
    </header>
    <div className='tag-list'>
      {[...Array(ROWS)].map((_, i) => (
        <InfiniteLoopSlider key={i} duration={random(DURATION - 5000, DURATION + 5000)} reverse={i % 2}>
          {shuffle(Object.keys(TAGS_WITH_IMAGES)).slice(0, TAGS_PER_ROW).map(tag => (
            <Tag text={tag} key={tag} />
          ))}
        </InfiniteLoopSlider>
      ))}
      <div className='fade' />
    </div>
  </div>
);

export default Section5;
