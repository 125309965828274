// // all images imported from images directory
// import product_01_image_01 from "../images/product_01.jpg";
// import product_01_image_02 from "../images/product_01.1.jpg";
// import product_01_image_03 from "../images/product_01.3.jpg";

// import product_02_image_01 from "../images/product_2.1.jpg";
// import product_02_image_02 from "../images/product_2.2.jpg";
// import product_02_image_03 from "../images/product_2.3.jpg";

// import product_03_image_01 from "../images/product_3.1.jpg";
// import product_03_image_02 from "../images/product_3.2.jpg";
// import product_03_image_03 from "../images/product_3.3.jpg";

// import product_04_image_01 from "../images/product_4.1.jpg";
// import product_04_image_02 from "../images/product_4.2.jpg";
// import product_04_image_03 from "../images/product_4.3.png";

// import product_05_image_01 from "../images/product_04.jpg";
// import product_05_image_02 from "../images/product_08.jpg";
// import product_05_image_03 from "../images/product_09.jpg";

// import product_06_image_01 from "../images/bread(1).png";
// import product_06_image_02 from "../images/bread(2).png";
// import product_06_image_03 from "../images/bread(3).png";


import boshti1 from '../img/bosht1.jpg'
import boshti2 from '../img/bosht2.jpg'
import boshti3 from '../img/bosht3.jpg'
import boshti4 from '../img/bosht4.jpg'
import boshti5 from '../img/bosht5.jpg'
import boshti6 from '../img/bosht6.png'
import boshti7 from '../img/bosht7.png'
import boshti8 from '../img/bosht8.jpg'
import boshti9 from '../img/bosht9.jpg'
import boshti10 from '../img/bosht10.png'

import barku from '../img/barku.jpg'
import basket from '../img/basket.jpg'
import cel from '../img/cel.jpg'
import cengel from '../img/cengel.png'
import doreza from '../img/doreza.png'
import everlast from '../img/everlast.webp'
import fasho from '../img/fasho.webp'
import formus from '../img/formus.jpg'
import handgrip from '../img/hangrip.webp'
import hekurtek from '../img/hekurtek.jpg'
import hekurtek1 from '../img/hekurtek1.jpg'
import kone from '../img/kone.jpg'
import korse from '../img/korse.jpg'
import korse1 from '../img/korse1.jpg'
import litar from '../img/litar.jpg'
import doni from '../img/doni.jpeg'
import maske from '../img/maske.jpg'
// import mbrojtesedhembesh from '../img/mbrojtesedhembesh.gif'
import pingpong from '../img/pingong.png'
import pompa from '../img/pompa.jpg'
import rrip from '../img/rrip.png'
import sauna from '../img/sauna.jpeg'
import set5 from '../img/set5.png'
import settenis from '../img/settenis.jpg'
import step from '../img/step.jpg'
import suportues from '../img/suportues.jpg'
import suportuesgjuri from '../img/suportuesgjuri.jpg'
import suportuesgjuri1 from '../img/suportuesgjuri1.png'
// import suportuskyc from '../img/suportuskyc.png'
import sust from '../img/sust.png'
import sweet from '../img/sweet.jpg'
import tapet from '../img/tapet.png'
import tenis from '../img/tenis.jpg'
// import thesboksi from '../img/thesboksi.png'
// import top from '../img/top.jpg'
import topbasket from '../img/topbasket.png'
import trampolin from '../img/trampolin.jpg'


const products = [

  //te pergjithshme:

  {
    id: '01',
    title: 'Push Up',
    price: 'xxx',
    image01: barku,
    category: 'Pajisje Sporti',
  },{
    id: '01',
    title: 'Kosh Basketballi',
    price: 'xxx',
    image01: basket,
    category: 'Pajisje Sporti',
  },{
    id: '01',
    title: 'Mbajtese Celulari',
    price: 'xxx',
    image01: cel,
    category: 'Pajisje Sporti',
  },{
    id: '01',
    title: 'Mbajtese Shtange',
    price: 'xxx',
    image01: cengel,
    category: 'Pajisje Sporti',
  },{
    id: '01',
    title: 'Doreza Termike',
    price: 'xxx',
    image01: doreza,
    category: 'gira',
  },{
    id: '01',
    title: 'Doreza Everlast',
    price: 'xxx',
    image01: everlast,
    category: 'Box',
  },{
    id: '01',
    title: 'Fasho Boksi',
    price: 'xxx',
    image01: fasho,
    category: 'Box',
  },{
    id: '01',
    title: 'Tapet Formimi',
    price: 'xxx',
    image01: formus,
    category: 'gira',
  },{
    id: '01',
    title: 'Handgripsa',
    price: 'xxx',
    image01: handgrip,
    category: 'Pajisje Sporti',
  },{
    id: '01',
    title: 'Hekur Tek',
    price: 'xxx',
    image01: hekurtek,
    category: 'Pajisje Sporti',
  },{
    id: '01',
    title: 'Hekur Tek',
    price: 'xxx',
    image01: hekurtek1,
    category: 'Pajisje Sporti',
  },{
    id: '01',
    title: 'Kone futballi',
    price: 'xxx',
    image01: kone,
    category: 'gira',
  },{
    id: '01',
    title: 'Korse Dobesimi',
    price: 'xxx',
    image01: korse,
    category: 'gira',
  },{
    id: '01',
    title: 'Mbrojtese mesi',
    price: 'xxx',
    image01: korse1,
    category: 'gira',
  },{
    id: '01',
    title: 'Litar Kercimi',
    price: 'xxx',
    image01: litar,
    category: 'Pajisje Sporti',
  },
  
  {
    id: '01',
    title: 'Set Llastiqesh',
    price: 'xxx',
    image01: doni,
    category: 'gira',
  },
  
  {
    id: '01',
    title: 'Mbrojtese Fytyre',
    price: 'xxx',
    image01: maske,
    category: 'gira',
  },{
    id: '01',
    title: 'Ping Pong Set',
    price: 'xxx',
    image01: pingpong,
    category: 'gira',
  },{
    id: '01',
    title: 'Push Ups',
    price: 'xxx',
    image01: pompa,
    category: 'Pajisje Sporti',
  },{
    id: '01',
    title: 'Rrip mesi',
    price: 'xxx',
    image01: rrip,
    category: 'Pajisje Sporti',
  },{
    id: '01',
    title: 'Sauna Suit',
    price: 'xxx',
    image01: sauna,
    category: 'gira',
  },{
    id: '01',
    title: 'Set llastiqesh 5',
    price: 'xxx',
    image01: set5,
    category: 'gira',
  },{
    id: '01',
    title: 'Set Tenisi',
    price: 'xxx',
    image01: settenis,
    category: 'gira',
  },{
    id: '01',
    title: 'Step',
    price: 'xxx',
    image01: step,
    category: 'Pajisje Sporti',
  },{
    id: '01',
    title: 'Mbrojtese Kycesh',
    price: 'xxx',
    image01: suportues,
    category: 'gira',
  },{
    id: '01',
    title: 'Suportues gjuri',
    price: 'xxx',
    image01: suportuesgjuri,
    category: 'gira',
  },{
    id: '01',
    title: 'Suportues gjuri',
    price: 'xxx',
    image01: suportuesgjuri1,
    category: 'gira',
  },
  // {
  //   id: '01',
  //   title: 'Suportues krahu',
  //   price: 'xxx',
  //   image01: suportuskyc,
  //   category: 'gira',
  // },
  {
    id: '01',
    title: 'Sust stervitje',
    price: 'xxx',
    image01: sust,
    category: 'Pajisje Sporti',
  },{
    id: '01',
    title: 'Sweat Belt',
    price: 'xxx',
    image01: sweet,
    category: 'gira',
  },{
    id: '01',
    title: 'Tapet Yoga',
    price: 'xxx',
    image01: tapet,
    category: 'gira',
  },{
    id: '01',
    title: 'Rrjete Tenisi',
    price: 'xxx',
    image01: tenis,
    category: 'gira',
  },
  // {
  //   id: '01',
  //   title: 'Pesha',
  //   price: 'xxx',
  //   image01: thesboksi,
  //   category: 'gira',
  // },
  // {
  //   id: '01',
  //   title: 'Pesha',
  //   price: 'xxx',
  //   image01: top,
  //   category: 'gira',
  // },
  
  {
    id: '01',
    title: 'Top Basketballi',
    price: 'xxx',
    image01: topbasket,
    category: 'gira',
  },{
    id: '01',
    title: 'Trampolin',
    price: 'xxx',
    image01: trampolin,
    category: 'Pajisje Sporti',
  },




  //girat dhe peshat

{
  id: '01',
  title: 'Pesha Hekuri',
  price: 'xxx',
  image01: boshti1,
  category: 'Gira',
},
{
  id: '01',
  title: 'Pesha Hekuri Gomine',
  price: 'xxx',
  image01: boshti2,
  category: 'Gira',
},{
  id: '01',
  title: 'Hekur per pesha',
  price: 'xxx',
  image01: boshti3,
  category: 'Gira',
},{
  id: '01',
  title: 'Pesha kycesh',
  price: 'xxx',
  image01: boshti4,
  category: 'Box',
},{
  id: '01',
  title: 'Bosht me gunga',
  price: 'xxx',
  image01: boshti5,
  category: 'Gira',
},{
  id: '01',
  title: 'Gira Iron Bull',
  price: 'xxx',
  image01: boshti6,
  category: 'Gira',
},
{
  id: '01',
  title: 'Gira Hekzanale',
  price: 'xxx',
  image01: boshti7,
  category: 'Gira',
},{
  id: '01',
  title: 'Set Girash',
  price: 'xxx',
  image01: boshti8,
  category: 'Gira',
},{
  id: '01',
  title: 'Gra fitness',
  price: 'xxx',
  image01: boshti9,
  category: 'Gira',
},{
  id: '01',
  title: 'Top i rende',
  price: 'xxx',
  image01: boshti10,
  category: 'Box',
},


// mbarojn girat e peshat der ktu



  // {
  //   id: "01",
  //   title: "Chicken Gira",
  //   price: 24.0,
  //   image01: boshti1,
  //   // image02: product_01_image_02,
  //   // image03: product_01_image_03,
  //   category: "Gira",

  //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque. ",
  // },

  // {
  //   id: "02",
  //   title: "Vegetarian Pizza",
  //   price: 115.0,
  //   image01: product_02_image_01,
  //   image02: product_02_image_02,
  //   image03: product_02_image_03,
  //   category: "Pizza",

  //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  // },

  // {
  //   id: "03",
  //   title: "Double Cheese Margherita",
  //   price: 110.0,
  //   image01: product_03_image_01,
  //   image02: product_03_image_02,
  //   image03: product_03_image_03,
  //   category: "Pizza",

  //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  // },

  // {
  //   id: "04",
  //   title: "Maxican Green Wave",
  //   price: 110.0,
  //   image01: product_04_image_01,
  //   image02: product_04_image_02,
  //   image03: product_04_image_03,
  //   category: "Pizza",

  //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  // },

  // {
  //   id: "05",
  //   title: "Cheese Gira",
  //   price: 24.0,
  //   image01: product_05_image_01,
  //   image02: product_05_image_02,
  //   image03: product_05_image_03,
  //   category: "Gira",

  //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  // },
  // {
  //   id: "06",
  //   title: "Royal Cheese Gira",
  //   price: 24.0,
  //   image01: product_01_image_01,
  //   image02: product_01_image_02,
  //   image03: product_01_image_03,
  //   category: "Gira",

  //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  // },

  // {
  //   id: "07",
  //   title: "Seafood Pizza",
  //   price: 115.0,
  //   image01: product_02_image_02,
  //   image02: product_02_image_01,
  //   image03: product_02_image_03,
  //   category: "Pizza",

  //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  // },

  // {
  //   id: "08",
  //   title: "Thin Cheese Pizza",
  //   price: 110.0,
  //   image01: product_03_image_02,
  //   image02: product_03_image_01,
  //   image03: product_03_image_03,
  //   category: "Pizza",

  //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  // },

  // {
  //   id: "09",
  //   title: "Pizza With Mushroom",
  //   price: 110.0,
  //   image01: product_04_image_02,
  //   image02: product_04_image_01,
  //   image03: product_04_image_03,
  //   category: "Pizza",

  //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  // },

  // {
  //   id: "10",
  //   title: "Classic HamGira",
  //   price: 24.0,
  //   image01: product_05_image_02,
  //   image02: product_05_image_01,
  //   image03: product_05_image_03,
  //   category: "Gira",

  //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  // },

  // {
  //   id: "11",
  //   title: "Crunchy Bread ",
  //   price: 35.0,
  //   image01: product_06_image_01,
  //   image02: product_06_image_02,
  //   image03: product_06_image_03,
  //   category: "Bread",

  //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  // },

  // {
  //   id: "12",
  //   title: "Delicious Bread ",
  //   price: 35.0,
  //   image01: product_06_image_02,
  //   image02: product_06_image_01,
  //   image03: product_06_image_03,
  //   category: "Bread",

  //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  // },

  // {
  //   id: "13",
  //   title: "Loaf Bread ",
  //   price: 35.0,
  //   image01: product_06_image_03,
  //   image02: product_06_image_02,
  //   image03: product_06_image_03,
  //   category: "Bread",

  //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta ad et est, fugiat repudiandae neque illo delectus commodi magnam explicabo autem voluptates eaque velit vero facere mollitia. Placeat rem, molestiae error obcaecati enim doloribus impedit aliquam, maiores qui minus neque.",
  // },
];

export default products;
