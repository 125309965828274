// import React, { Component } from 'react';
// import classNames from 'classnames';
// import "./Reviews.css"
// // import Section3 from './Sextion3';
// import gira1 from '../assets/gira1.png';
// // import background from '../assets/background.jpg'
// // import doni2 from '../assets/doni2.jpg'


// class Section4 extends Component {
//   constructor(props) {
//     super(props);

//     this.IMAGE_PARTS = 4;

//     this.changeTO = null;
//     this.AUTOCHANGE_TIME = 5000;

//     this.state = { activeSlide: -1, prevSlide: -1, sliderReady: false };
//   }

//   componentWillUnmount() {
//     window.clearTimeout(this.changeTO);
//   }

//   componentDidMount() {
//     this.runAutochangeTO();
//     setTimeout(() => {
//       this.setState({ activeSlide: 0, sliderReady: true });
//     }, 0);
//   }

//   runAutochangeTO() {
//     this.changeTO = setTimeout(() => {
//       this.changeSlides(1);
//       this.runAutochangeTO();
//     }, this.AUTOCHANGE_TIME);
//   }

//   changeSlides(change) {
//     window.clearTimeout(this.changeTO);
//     const { length } = this.props.slides;
//     const prevSlide = this.state.activeSlide;
//     let activeSlide = prevSlide + change;
//     if (activeSlide < 0) activeSlide = length - 1;
//     if (activeSlide >= length) activeSlide = 0;
//     this.setState({ activeSlide, prevSlide });
//   }

//   render() {
//     const { activeSlide, prevSlide, sliderReady } = this.state;
//     return (
//       <div className={classNames("slider", { "s--ready": sliderReady })}>
//         {/* <p className="slider__top-heading">Travelers</p> */}
//         <div className="slider__slides">
//           {this.props.slides.map((slide, index) => (
//             <div
//               className={classNames("slider__slide", {
//                 "s--active": activeSlide === index,
//                 "s--prev": prevSlide === index
//               })}
//               key={slide.city}
//             >
//               <div className="slider__slide-content">
//                 <h3 className="slider__slide-subheading">
//                   {slide.country || slide.city}
//                 </h3>
//                 <h2 className="slider__slide-heading">
//                   {slide.city.split("").map((l, i) => (
//                     <span key={i}>{l}</span>
//                   ))}
//                 </h2>
//                 <p className="slider__slide-readmore"> </p>
//               </div>
//               <div className="slider__slide-parts">
//                 {[...Array(this.IMAGE_PARTS).fill()].map((x, i) => (
//                   <div className="slider__slide-part" key={i}>
//                     <div
//                       className="slider__slide-part-inner"
//                       style={{ backgroundImage: `url(${slide.img})` }}
//                     />
//                   </div>
//                 ))}
//               </div>
//             </div>
//           ))}
//         </div>
//         {/* <div
//           className="slider__control"
//           onClick={() => this.changeSlides(-1)}
//         />
//         <div
//           className="slider__control slider__control--right"
//           onClick={() => this.changeSlides(1)}
//         /> */}
//       </div>
//     );
//   }
// }

// const slides = [
//   {
//     city: 'Reviews',
//     country: 'Clients',
//     img: gira1
// //   },
// //   {
// //     city: 'Reviews',
// //     country: 'Clients',
// //     img: background
// //   },
// //   {
// //     city: 'Reviews',
// //     country: 'Clients',
// //     img: doni2
//   },
// ];

// function App() {
//   return (
//     <div id="app">
//       <Section4 slides={slides} />
//     </div>
//   );
// }

// export default App;




















//////////////////////////////////// kodi 2 me videon ////////////////////////////














// import React, { Component } from 'react';
// import classNames from 'classnames';
// import "./Reviews.css"
// import video1 from '../assets/video/video1.mp4'

// class Section4 extends Component {
//   constructor(props) {
//     super(props);

//     this.VIDEO_PARTS = 1;
//     this.state = { activeSlide: -1, prevSlide: -1, sliderReady: false };
//     this.AUTOCHANGE_TIME = 5000;
//     this.changeTO = null;
//   }

//   componentWillUnmount() {
//     window.clearTimeout(this.changeTO);
//   }

//   componentDidMount() {
//     this.runAutochangeTO();
//     setTimeout(() => {
//       this.setState({ activeSlide: 0, sliderReady: true });
//     }, 0);
//   }

//   runAutochangeTO() {
//     this.changeTO = setTimeout(() => {
//       this.changeSlides(1);
//       this.runAutochangeTO();
//     }, this.AUTOCHANGE_TIME);
//   }

//   changeSlides(change) {
//     window.clearTimeout(this.changeTO);
//     const { slides } = this.props;
//     const { length } = slides;
//     const { activeSlide } = this.state;
//     let nextSlide = activeSlide + change;
//     if (nextSlide < 0) nextSlide = length - 1;
//     if (nextSlide >= length) nextSlide = 0;
//     this.setState({ activeSlide: nextSlide, prevSlide: activeSlide });
//   }

//   render() {
//     const { activeSlide, prevSlide, sliderReady } = this.state;
//     return (
//       <div className={classNames("slider", { "s--ready": sliderReady })}>
//         <div className="slider__slides">
//           {this.props.slides.map((slide, index) => (
//             <div
//               className={classNames("slider__slide", {
//                 "s--active": activeSlide === index,
//                 "s--prev": prevSlide === index
//               })}
//               key={slide.city}
//             >
//               <div className="slider__slide-content">
//                 <h3 className="slider__slide-subheading">
//                   {slide.country || slide.city}
//                 </h3>
//                 <h2 className="slider__slide-heading">
//                   {slide.city.split("").map((l, i) => (
//                     <span key={i}>{l}</span>
//                   ))}
//                 </h2>
//                 <p className="slider__slide-readmore"> </p>
//               </div>
//               <div className="slider__slide-parts">
//                 {[...Array(this.VIDEO_PARTS).fill()].map((x, i) => (
//                   <div className="slider__slide-part" key={i}>
//                     <div className="slider__slide-part-inner">
//                       {/* Video tag for displaying the video */}
//                       <video controls key={i}>
//                         <source src={slide.video} type="video/mp4" />
//                         Your browser does not support the video tag.
//                       </video>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     );
//   }
// }

// // ... slides and App component remain unchanged
// const slides = [
//   {
//     city: 'Reviews',
//     country: 'Clients',
//     video: video1,
//   },
//   // Add more objects for additional videos if needed
// ];

// function App() {
//   return (
//     <div id="app">
//       <Section4 slides={slides} />
//     </div>
//   );
// }

// export default App;



















//////////////////////// kodi 3 me videon ///////////////////



import React, { Component } from 'react';
import classNames from 'classnames';
import "./Reviews.css";
import video1 from '../assets/video/video1.mp4'; // Import the video file

class Section4 extends Component {
  constructor(props) {
    super(props);

    this.state = { activeSlide: 0, sliderReady: false };
  }

  componentDidMount() {
    this.setState({ sliderReady: true });
  }

  render() {
    const { activeSlide, sliderReady } = this.state;
    return (
      <div className={classNames("slider", { "s--ready": sliderReady })}>
        <div className="slider__slides">
          {this.props.slides.map((slide, index) => (
            <div
              className={classNames("slider__slide", {
                "s--active": activeSlide === index
              })}
              key={slide.city}
            >
              <div className="slider__slide-content">
                <h3 className="slider__slide-subheading">
                  {slide.country || slide.city}
                </h3>
                <h2 className="slider__slide-heading">
                  {slide.city.split("").map((l, i) => (
                    <span key={i}>{l}</span>
                  ))}
                </h2>
                <p className="slider__slide-readmore"> </p>
              </div>
              <div className="slider__slide-parts">
                <div className="slider__slide-part" key={index}>
                  <div className="slider__slide-part-inner">
                    {/* Video tag for displaying the video */}
                    <video autoPlay controls loop muted className="full-video">
                      <source src={slide.video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const slides = [
  {
    city: 'Sportive',
    country: 'Numri 1 ne treg',
    video: video1 // Use the imported video in your slides data
  },
  // Add more objects for additional videos if needed
];

function App() {
  return (
    <div id="app">
      <Section4 slides={slides} />
    </div>
  );
}

export default App;
