// import React from 'react';
// import './WhyUs.css';
// import dumbell from '../assets/dumbell.png'
// // import pesha from '../assets/pesha.png'
// import freedelivery from '../assets/freedelivery.png'
// // import shipping from '../assets/shipping.png'
// import quality from '../assets/quality.png'
// import alb from '../assets/alb.png'

// const WhyUs = () => {

//   return (
//     <section className="section feature" aria-label="feature" data-section>
//       <hr
//       style={{	
//         height: '10px',
//         border: '0',
//         boxShadow: '0 10px 10px -10px #8c8b8b inset',
//         marginBottom: '60px',
//       }}
//       ></hr>
//       <div className="container">
//         <h2 className="h2-large section-title">Pse te blesh tek NE ???</h2>

//         <ul className="flex-list" style={{ display: 'flex', justifyContent: 'space-around', listStyle: 'none', padding: 0 }}>
//           <li className="flex-item" style={{ flex: '1', margin: '0 10px' }}>
//             <div className="feature-card">
//               <img
//                 src= {quality}
//                 width="200px"
//                 height="136px"
//                 loading="lazy"
//                 alt="Guaranteed PURE"
//                 className="card-icon"
//               />
//               <h3 className="h3 card-title">Kualitet i Garantuar</h3>
//               <p className="card-text">
//               Zbuloni përsosmërine në cdo pajisje tonen. 
//               Të krijuara me materialet më të mira dhe teknologjinë më të fundit pajisjet tona japin performancen dhe qëndrueshmërine me te larte. 
//               Përjetoni ndryshimin në çdo lëvizje, çdo sprint dhe çdo 
//               fitore me pajisjet tona të jashtëzakonshme sportive."              
//               </p>
//             </div>
//           </li>

//           <li className="flex-item" style={{ flex: '1', margin: '0 10px' }}>
//             <div className="feature-card">
//               <img
//                 src={dumbell}
//                 width="200"
//                 height="136"
//                 loading="lazy"
//                 alt="Completely Cruelty-Free"
//                 className="card-icon"
//               />
//               <h3 className="h3 card-title">Pajisjet e fundit</h3>
//               <p className="card-text">
//               {/* Arsyeja pse ne kemi të gjitha produktet sportive është sepse ne besojmë ne shperndarjen e pasionit për sportin në mënyrë të barabartë për të gjithë.  */}
//               Ne punojmë për të siguruar një gamë të gjerë të produkteve sportive sepse e kuptojmë 
//               rëndësinë e pajisjeve të mira dhe të përshtatshme, kështu që jemi këtu për të ofruar zgjidhje cilësore për nevojat sportive të të gjithëve.
//               </p>
//             </div>
//           </li>

//           <li className="flex-item" style={{ flex: '1', margin: '0 10px' }}>
//             <div className="feature-card">
//               <img
//                 src={freedelivery}
//                 width="200"
//                 height="136"
//                 loading="lazy"
//                 alt="Ingredient Sourcing"
//                 className="card-icon"
//               />
//               <h3 className="h3 card-title">Posta falas <img style={{width: '30px', height: '30px'}} src={alb} alt="Description of the image"/></h3>
//               <p className="card-text">
//                 Ne kemi marre persiper qe t ju ofrojme POSTEN FALAS ne gjithe republiken e Shqiperise!
//                 Cdo kosto per posten e paguajm NE!
//                 Brenda Tiranes 24-48 ore max vonesa ne poste!
//                 Ne rrethe 48 ore max dhe ne Kosove/Macedoni 3-5 dite!
//               </p>
//             </div>
//           </li>
//         </ul>
//       </div>
//     </section>
//   );
// };

// export default WhyUs;

import React from 'react';
import './WhyUs.css';
import dumbell from '../assets/dumbell.png'
import freedelivery from '../assets/freedelivery.png'
import quality from '../assets/quality.png'
import alb from '../assets/alb.png'

const WhyUs = () => {

  return (
    <section className="section feature" aria-label="feature" data-section>
      <hr
        style={{
          height: '10px',
          border: '0',
          boxShadow: '0 10px 10px -10px #8c8b8b inset',
          marginBottom: '60px',
        }}
      ></hr>
              <h2 className="h2-large section-title">Pse te blesh tek NE ???</h2>
      <div className="container">
        <ul className="flex-list" style={{ display: 'flex', justifyContent: 'space-around', listStyle: 'none', padding: 0 }}>
          <li className="flex-item" style={{ flex: '1', margin: '0 10px' }}>
            <div className="feature-card">
              <img
                src={quality}
                width="200px"
                height="136px"
                loading="lazy"
                alt="doni"
                className="card-icon"
              />
              <h3 className="h3 card-title">Kualitet i Garantuar</h3>
              <p className="card-text">
                Zbuloni përsosmërinë në çdo pajisje tonen.
                Të krijuara me materialet më të mira dhe teknologjinë më të fundit pajisjet tona japin performancen dhe qëndrueshmërine me te larte.
                Përjetoni ndryshimin në çdo lëvizje, çdo sprint dhe çdo fitore me pajisjet tona të jashtëzakonshme sportive.
              </p>
            </div>
          </li>

          <li className="flex-item" style={{ flex: '1', margin: '0 10px' }}>
            <div className="feature-card">
              <img
                src={dumbell}
                width="200"
                height="136"
                loading="lazy"
                alt='doni'
                className="card-icon"
              />
              <h3 className="h3 card-title">Pajisjet e fundit</h3>
              <p className="card-text">
                Ne punojmë për të siguruar një gamë të gjerë të produkteve sportive sepse e kuptojmë
                rëndësinë e pajisjeve të mira dhe të përshtatshme, kështu që jemi këtu për të ofruar zgjidhje cilësore për nevojat sportive të të gjithëve.
              </p>
            </div>
          </li>

          <li className="flex-item" style={{ flex: '1', margin: '0 10px' }}>
            <div className="feature-card">
              <img
                src={freedelivery}
                width="200"
                height="136"
                loading="lazy"
                alt="Ingredient Sourcing"
                className="card-icon"
              />
              <h3 className="h3 card-title">Posta falas <img style={{ width: '30px', height: '30px' }} src={alb} alt="doni" /></h3>
              <p className="card-text">
                Ne kemi marre persiper qe t ju ofrojme POSTEN FALAS ne gjithe Shqiperine!
                Cdo kosto per posten e paguajm NE!
                Brenda Tiranes 24-48 ore max vonesa ne poste!
                Ne rrethe 48 ore max dhe ne Kosove/Macedoni 5-7 dite!
              </p>
            </div>
          </li>
        </ul>
      </div>
      <hr style={{
        // marginBottom: '40px',
        height: '10px',
        border: '0',
        boxShadow: '0 10px 10px -10px #8c8b8b inset',
        marginBottom: '60px',
        }}></hr>
    </section>
  );
};

export default WhyUs;

