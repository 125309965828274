// import React, { Component } from 'react';
// import instagram from '../assets/instagram.png';
// import whatsapp from '../assets/whatsapp.png'

// class BeforeNavbar extends Component {
//     render() {
//         return (
//             <div style={{backgroundImage: 'linear-gradient(to right, #fffafa 50%, #f5f5f5 )'}}>
//                 <div  style= {{display: "flex", justifyContent: 'center', marginRight: "55px", marginLeft: "35px"}}>
//                     <p style={{
//                         fontSize: '15px',
//                         marginTop: '5px'
//                     }}>Ne Shqiperi <span style={{color: 'red'}}>POSTA</span> falas , ne Kosove dhe Macedoni <span style={{color: 'red'}}>POSTA</span> 3-5$</p>
//                     <a href='https://www.flaticon.com/search?word=whatsapp'><img style={{width: '30px', height: '30px', marginTop: "3px", marginLeft: "30px"}} src={instagram} alt=''></img></a>
//                     <a href='https://www.flaticon.com/search?word=whatsapp'><img style={{width: '30px', height: '30px', marginTop: "3px", marginLeft: "20px"}} src={whatsapp} alt=''></img></a>
//                 </div>
//             </div>
//         );
//     }
// }

// export default BeforeNavbar;


import React, { Component } from 'react';
import instagram from '../assets/instagram.png';
// import whatsapp from '../assets/whatsapp.png';

class BeforeNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSmallDevice: false,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ isSmallDevice: window.innerWidth < 600 });
    };

    render() {
        const { isSmallDevice } = this.state;

        const infoTextStyle = {
            fontSize: '15px',
            marginTop: '5px',
        };

        const infoTextStyleSmallDevice = {
            fontSize: '12px',
            marginTop: '5px',
        };

        return (
            <div style={{backgroundImage: 'linear-gradient(to right, #fffafa 50%, #f5f5f5 )'}}>
                <div style={{display: "flex", justifyContent: 'center', marginRight: "55px", marginLeft: "35px"}}>
                    <p style={isSmallDevice ? infoTextStyleSmallDevice : infoTextStyle} className="info-text">
                        Ne Shqiperi <span style={{color: 'red'}}>POSTA</span> falas , ne Kosove dhe Macedoni <span style={{color: 'red'}}>POSTA</span> 3-5$
                    </p>
                    <a href='https://www.instagram.com/_pajisjesportive/'><img style={{width: '30px', height: '30px', marginTop: "3px", marginLeft: "30px"}} src={instagram} alt=''></img></a>
                    {/* <a href='https://www.flaticon.com/search?word=whatsapp'><img style={{width: '30px', height: '30px', marginTop: "3px", marginLeft: "20px"}} src={whatsapp} alt=''></img></a> */}
                </div>
            </div>
        );
    }
}

export default BeforeNavbar;
