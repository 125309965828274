// import React,{ useState, useEffect } from 'react'
// import '../components/Super.css'
// import { Container, Row, Col } from "reactstrap";

// import products from "../assets/fake-data/products.js";
// import ProductCard from './ProductCard.jsx';

// import foodCategoryImg01 from "../assets/doni1.jpg";
// import foodCategoryImg02 from "../assets/doni1.jpg";
// import foodCategoryImg03 from "../assets/doni1.jpg";


// function Super() {
//     const [category, setCategory] = useState("ALL");
//     const [allProducts, setAllProducts] = useState(products);
  
//     // const [hotAerobi, setHotAerobi] = useState([]);
  
//     // useEffect(() => {
//     //   const filteredAerobi = products.filter((item) => item.category === "Aerobi");
//     //   const sliceAerobi = filteredAerobi.slice(0, 4);
//     //   setHotAerobi(sliceAerobi);
//     // }, []);
  
//     useEffect(() => {
//       if (category === "ALL") {
//         setAllProducts(products);
//       }
  
//       if (category === "Pajisje Sporti") {
//         const filteredProducts = products.filter(
//           (item) => item.category === "Pajisje Sporti"
//         );
  
//         setAllProducts(filteredProducts);
//       }
  
//       if (category === "Aerobi") {
//         const filteredProducts = products.filter(
//           (item) => item.category === "Aerobi"
//         );
  
//         setAllProducts(filteredProducts);
//       }
  
//       if (category === "Gira") {
//         const filteredProducts = products.filter(
//           (item) => item.category === "Gira"
//         );
  
//         setAllProducts(filteredProducts);
//       }
//     }, [category]);
  

//   return (
// <section>
// <Container style={{marginTop: '70px'}}>
//   <Row>
//     <Col lg="12" className="text-center">
//       <h2>Te gjitha pajisjet sportive</h2>
//     </Col>

//     <Col lg="12">
//       <div className="food__category d-flex align-items-center justify-content-center gap-4">
//         <button
//           className={`all__btn  ${
//             category === "ALL" ? "foodBtnActive" : ""
//           } `}
//           onClick={() => setCategory("ALL")}
//         >
//           All
//         </button>
//         <button
//           className={`d-flex align-items-center gap-2 ${
//             category === "Pajisje Sporti" ? "foodBtnActive" : ""
//           } `}
//           onClick={() => setCategory("Pajisje Sporti")}
//         >
//           <img src={foodCategoryImg01} alt="" />
//           Pajisje Sporti
//         </button>

//         <button
//           className={`d-flex align-items-center gap-2 ${
//             category === "Aerobi" ? "foodBtnActive" : ""
//           } `}
//           onClick={() => setCategory("Aerobi")}
//         >
//           <img src={foodCategoryImg02} alt="" />
//           Aerobi
//         </button>

//         <button
//           className={`d-flex align-items-center gap-2 ${
//             category === "Gira" ? "foodBtnActive" : ""
//           } `}
//           onClick={() => setCategory("Gira")}
//         >
//           <img src={foodCategoryImg03} alt="" />
//           Gira
//         </button>
//       </div>
//     </Col>

//     {allProducts.map((item) => (
//       <Col lg="3" md="4" sm="6" xs="6" key={item.id} className="mt-5">
//         <ProductCard item={item} />
//       </Col>
//     ))}
//   </Row>
// </Container>

// <hr style={{
//         // marginBottom: '40px',
//         height: '10px',
//         border: '0',
//         boxShadow: '0 10px 10px -10px #8c8b8b inset',
//         marginBottom: '60px',
//         }}></hr>
        
// </section>






//   )
// }

// export default Super




import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ProductCard from './ProductCard.jsx';
import '../components/Super.css';
import products from '../assets/fake-data/products.js';
import foodCategoryImg01 from '../assets/doni1.jpg';
import foodCategoryImg02 from '../assets/doni1.jpg';
import foodCategoryImg03 from '../assets/doni1.jpg';

const ITEMS_PER_PAGE = 12; // Number of items to display per page

function Super() {
  const [category, setCategory] = useState('ALL');
  const [allProducts, setAllProducts] = useState(products);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (category === 'ALL') {
      setAllProducts(products);
    } else {
      const filteredProducts = products.filter((item) => item.category === category);
      setAllProducts(filteredProducts);
    }
  }, [category]);

  // Calculate the indexes of the items to display for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentProducts = allProducts.slice(startIndex, endIndex);

  return (
    <section id='produktet'>
      <Container style={{ marginTop: '70px' }}>
        <Row>
          {/* ... (Your existing code) */}
          <Col lg="12" className="text-center">
       <h2>Te gjitha pajisjet sportive</h2>
     </Col>

     <Col lg="12">
       <div className="food__category d-flex align-items-center justify-content-center gap-4">
         <button
          className={`all__btn  ${
            category === "ALL" ? "foodBtnActive" : ""
          } `}
          onClick={() => setCategory("ALL")}
        >
          All
        </button>
        <button
          className={`d-flex align-items-center gap-2 ${
            category === "Pajisje Sporti" ? "foodBtnActive" : ""
          } `}
          onClick={() => setCategory("Pajisje Sporti")}
        >
          <img src={foodCategoryImg01} alt="" />
          Pajisje Sporti
        </button>

        <button
          className={`d-flex align-items-center gap-2 ${
            category === "Aerobi" ? "foodBtnActive" : ""
          } `}
          onClick={() => setCategory("Box")}
        >
          <img src={foodCategoryImg02} alt="" />
          Box
        </button>

        <button
          className={`d-flex align-items-center gap-2 ${
            category === "Gira" ? "foodBtnActive" : ""
          } `}
          onClick={() => setCategory("Gira")}
        >
          <img src={foodCategoryImg03} alt="" />
          Gira
        </button>
      </div>
    </Col>

          {currentProducts.map((item) => (
            <Col lg="3" md="4" sm="6" xs="6" key={item.id} className="mt-5">
              <ProductCard item={item} />
            </Col>
          ))}
        </Row>


        {/* Pagination */}
        <Row>
          <Col lg="12" className="text-center">
            <nav aria-label="Page navigation">
              <ul className="pagination justify-content-center" style={{marginTop: '20px'}}>
                {Array(Math.ceil(allProducts.length / ITEMS_PER_PAGE))
                  .fill()
                  .map((_, index) => (
                    <li
                    style={{margin: '3px'}}
                      key={index}
                      className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
      {/* ... (Your existing code) */}
      <hr style={{
        // marginBottom: '40px',
        height: '10px',
        border: '0',
        boxShadow: '0 10px 10px -10px #8c8b8b inset',
        marginBottom: '60px',
        }}></hr>
    </section>
  );
}

export default Super;
