import React, { Fragment } from "react";
import w001 from '../assets/w001.png';
import wo2 from "../assets/wo2.png";
import wo3 from "../assets/wo3.png";
import MediaQuery from "react-responsive";
// import { FaInstagram, FaMailBulk } from "react-icons/fa";
import world1 from '../assets/world1.jpg'
// import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    // faFacebook,
    faInstagram,
    // faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";


function Section6() {
  return (
    <Fragment>
      <MediaQuery minDeviceWidth={701}>
        <div id="posta">
        <div
          style={{
            marginTop: "30px",
            textAlign: "center",
            backgroundImage: "url(" + world1 + ")",
            backgroundSize: "cover",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h1> 
              <span style={{ color: "red" }}>POSTA</span> kudo ne Shqiperise , Kosoves dhe Macedonise <br /> Ne <span style={{ color: "red" }}>Shqiperi</span> FALAS
            </h1>
            <div style={{ marginTop: "70px" }}>
              <img
                style={{
                  width: "250px",
                  height: "250px",
                  borderRadius: "250px",
                }}
                src={wo2}
                alt=""
              />
              <img
                style={{ width: "250px", height: "250px" , borderRadius: "250px",
              }}
                src={w001}
                alt=""
              />
              <img
                style={{ width: "300px", height: "300px", borderRadius: "50px", }}
                src={wo3}
                alt=""
              />
            </div>
            <h3 style={{ marginTop: "60px" }}>
              Dergesat ne Shqiperi kryhen brenda 24-48 oreve , Kosove dhe Macedoni brenda 5-7 diteve
            </h3>
            <h1 style={{ marginTop: '25px' }}>Porosit ne Instagram </h1>
          </div>        
          <div style={{ fontSize: "3.5em", textAlign: 'center'}}>
          <a href="https://www.instagram.com/_pajisjesportive/" style={{color: 'black', padding: '20px'}}> 
          <FontAwesomeIcon icon={faInstagram} />
          </a> 
          {/* <a href="https://ig.me/m/pixcel.albaniaa" style={{color: 'black', padding: '20px'}}>
          <FontAwesomeIcon icon={faWhatsapp} />
          </a> */}
            </div>
        </div>
        </div>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={700}>
        <div style={{ marginTop: "80px", textAlign: "center" }}>
          <h1>
            <span style={{ color: "red" }}>Posta</span> ne Shqiperi falas!
          </h1>
          <div>
            <img
              style={{ width: "150px", height: "150px", borderRadius: "250px" }}
              src={w001}
              alt=""
            />
            <img style={{ width: "150px", height: "150px" }} src={wo3} alt="" />
          </div>
          <h3 style={{ fontSize: "20px", marginTop: "20px" }}>
            Bejme dergesa dhe ne Kosove / Macedoni
          </h3>
          <h1 style={{ marginTop: "10px" }}>
            Porosit ne 
          </h1>
          <span style={{ fontSize: "3em" }}>
          <a href="https://www.instagram.com/_pajisjesportive/" style={{color: 'black', padding: '10px'}}> 
          <FontAwesomeIcon icon={faInstagram} />
          </a> 
          {/* <a href="https://ig.me/m/pixcel.albaniaa" style={{color: 'black', padding: '20px'}}> 
          <FontAwesomeIcon icon={faWhatsapp} />
          </a> */}
          </span>
        </div>
      </MediaQuery>
    </Fragment>
  );
}

export default Section6;
