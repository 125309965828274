import BeforeNavbar from "../components/BeforeNavbar"
import Navbar1 from "../components/Navbar"
import Crs from "../components/Section2"
import Section4 from '../components/Reviews'
import Section5 from "../components/Kjoqereshqet"
import WhyUs from "../components/WhyUs"
// import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer/Footer"
import GoogleMap from "../components/GoogleMap"
import WhatsAppIcon from "../components/Whtsp"
import Section6 from "../components/Section6"
import FloatingInstagramIcon from "../components/Instagram"
// import Section55 from "../components/Section5"
// import GymImageList from "../components/GymimageList"
// import Komponent from "../components/Komponent"
// import Prove from "../components/Prove"
import TestimonialSlider from "../components/Testimonial"
import Super from "../components/Super"

function Home() {
return (
    <div style = {{overflowX: "hidden"}}>
        <BeforeNavbar />
        <Navbar1 />
        <Section4 />
        <Section5 />
        <Crs />
        <WhyUs />
        <Super />
        {/* <WhyUs /> */}
        {/* <ContactForm /> */}
        {/* <Section55 /> */}
        {/* <GymImageList /> */}

        {/* <Komponent /> */}


        <TestimonialSlider />
        <Section6 />

        {/* <Prove /> */}



        <GoogleMap />
        <WhatsAppIcon />
        <FloatingInstagramIcon />
        <Footer />
    </div>
)
}

export default Home




// import BeforeNavbar from "../components/BeforeNavbar"
// import Navbar1 from "../components/Navbar"
// import Crs from "../components/Section2"
// import Section4 from '../components/Reviews'
// import Section5 from "../components/Kjoqereshqet"
// import WhyUs from "../components/WhyUs"
// // import ContactForm from "../components/ContactForm"
// import Footer from "../components/Footer/Footer"
// import GoogleMap from "../components/GoogleMap"
// import WhatsAppIcon from "../components/Whtsp"
// import Section6 from "../components/Section6"
// import FloatingInstagramIcon from "../components/Instagram"
// import Section55 from "../components/Section5"
// import GymImageList from "../components/GymimageList"

// function Home() {
// return (
//     <div style = {{overflowX: "hidden"}}>
//         <BeforeNavbar />
//         <Navbar1 />
//         <Section4 />
//         <Section5 />
//         <Crs />
//         <WhyUs />
//         {/* <ContactForm /> */}
//         {/* <Section55 /> */}
//         <GymImageList />
//         <Section6 />
//         <GoogleMap />
//         <WhatsAppIcon />
//         <FloatingInstagramIcon />
//         <Footer />
//     </div>
// )
// }

// export default Home