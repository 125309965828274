import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsAppIcon = () => {
  const handleWhatsAppClick = () => {
    // Replace 'your_number' with the actual phone number or API specifics
    const phoneNumber = '+355693328788; // Change this to your WhatsApp number';
    const message = 'Pershendetje, Isha i interesuar per : ';
    window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
  };

  const iconStyles = {
    cursor: 'pointer',
    color: 'green', // Change color here to apply to all icons
    // backgroundColor: 'green',
    // borderRadius: '0%',
  };

  return (
    <div
      style={{
        // borderRadius: '50%',
        position: 'fixed',
        bottom: '65px',
        right: '10px',
        zIndex: 1000, // Ensure it's above other content
      }}
    >
      <FontAwesomeIcon
        icon={faWhatsapp}
        size="4x"
        style={iconStyles}
        onClick={handleWhatsAppClick}
      />
    </div>
  );
};

export default WhatsAppIcon;
