// import React from "react";

// export default function GoogleMap() {
//   return (
//     <section>
//       <div id="GoogleMap" class="map" style={{marginTop: '25px'}}>
//         <iframe
//         title="addresa"
//             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11984.930237481512!2d19.79145761186951!3d41.32555609092085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350310470fac5db%3A0x40092af10653720!2sTirana%2C%20Albania!5e0!3m2!1sen!2s!4v1699906346470!5m2!1sen!2s"
//             style= {{
//                 border: "0px",
//                 width: "100%",
//                 height: "450px",
//                 frameborder: "0",
//                 marginBottom: '-3px',
//                 alt: 'd',
//                 // marginTop: '10px'
//                 // style: "border: 0",
//             // allowfullscreen="",
//             // referrerpolicy="no-referrer-when-downgrade"
//             }}
//             // loading="lazy"
//           >
//         </iframe>
//       </div>
//     </section>
//   );
// }
// {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11984.930237481512!2d19.79145761186951!3d41.32555609092085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350310470fac5db%3A0x40092af10653720!2sTirana%2C%20Albania!5e0!3m2!1sen!2s!4v1699906346470!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}


import React from "react";

export default function GoogleMap() {
  return (
    <section>
      <div id="GoogleMap" className="map" style={{ marginTop: '1px' }}>
        <iframe
          title="address"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11984.930237481512!2d19.79145761186951!3d41.32555609092085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350310470fac5db%3A0x40092af10653720!2sTirana%2C%20Albania!5e0!3m2!1sen!2s!4v1699906346470!5m2!1sen!2s"
          style={{
            border: "0px",
            width: "100%",
            height: "450px",
            frameborder: "0",
            marginBottom: '-3px',
          }}
          allowFullScreen=""
          referrerPolicy="no-referrer-when-downgrade"
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
}
