import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const PrivacyModal = (props) => {
    const [open, setOpen] = React.useState(false);
    const policyText = (
        <p>
            Politika e Privatësisë për 'Pajisje Sportive' <br /> NUK RUAJME ASNJE INFORMACION PERSONAL MBI PERDORUESIT!<hr></hr>

E përditësuar për herë te fundit: 2023 <br></br>

1. Hyrje <br></br>

Mirë se vini në PajisjeSportive. Kjo Politikë e Privatësisë është e projektuar për t'ju informuar rreth 
llojeve të informacionit që mbledhim, se si e përdorim atë, dhe zgjedhjet që keni në lidhje me informacionin 
tuaj. Duke përdorur faqen tonë, ju pajtoheni me kushtet e përcaktuara në këtë politikë. <hr></hr>

2. Informacioni që  ne mbledhim: <br></br>

a. Informacion Personal: <br></br>
-Nuk ruajm asnje informacion personal! <br></br>

b. Informacioni jo Personal: <br></br>
-Nuk rruajm asnje informacon jo-personal! <hr></hr>

3. Siguria e të Dhënave <br></br>

Aplikojmë masa të sigurisë standarde të industrisë për të mbrojtur informacionin tua. <hr></hr>

4. Shërbimet e Treta <br></br>

Mund të përdorim shërbime të treta, si procesorë pagese, për të lehtësuar transaksionet. Këto shërbime kanë politikat e
tyre të privatësisë, dhe ju inkurajojmë t'i shqyrtoni ato.<hr></hr>

{/* 5. Privatësia e Fëmijëve<br></br>

Faqja jonë nuk është e orientuar për individë mbi moshën 18 vjeç. Nuk mbledhim asnje informacion s per persona mbi 18 vjec si per persona nen! <hr></hr> */}

{/* 6. Ndryshimet në Këtë Politikë të Privatësisë<br></br>

Mbajmë të drejtën të përditësojmë këtë Politikë të Privatësisë në çdo kohë. Data e revizionit të fundit do të shfaqet në krye të kësaj faqeje. <hr></hr> */}

5. Na Kontaktoni<br></br>

Nëse keni pyetje rreth kësaj Politike të Privatësisë, ju lutemi na kontaktoni në [pajisjesportive@gmail.com].
        </p>
    );
    return (
        <>
            <button className="item1" onClick={() => setOpen(true)}>
                Privacy Policy
            </button>
            <Modal open={open} onClose={() => setOpen(false)} center>
                <h2>Privacy Policy</h2>
                {policyText}
                {/* {policyText}
                {policyText} */}
            </Modal>
        </>
    );
};

export default PrivacyModal;
