import React from "react";
import './Section2.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// import handgrip from '../assets/fotoCaruseli/handgrip.png'
// import handgrips from '../assets/fotoCaruseli/handgrips.png'

const Crs = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }  
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const slides = [
    { title: "Handgrip", price: "Cmimi: 1600 ALL", image: "https://alitools.io/es/showcase/image?url=https%3A%2F%2Fae01.alicdn.com%2Fkf%2FH08f1890dc85a469f8ec369663da60ff7Z.jpg_480x480.jpg_.webp" },
    { title: "Tapet Yoga", price: "Cmimi: 2300 ALL", image: "https://evafoamrubber.com/wp-content/uploads/2022/01/WeChat-Image_20220101203018.png" },
    { title: "Top Aerobie", price: "Cmimi: 1800 ALL", image: "https://cdn.thewirecutter.com/wp-content/media/2023/02/exerciseballs-2048px-09602-2x1-1.jpg?auto=webp&quality=75&crop=2:1&width=1024" },
    { title: "Kostum Sauna", price: "Cmimi: 2200 ALL", image: "https://img.joomcdn.net/e29daf94de69643be7e826008aeb3d0edc3fc78e_original.jpeg" },

    { title: "Llastik Stervitjeje", price: "Cmimi: 1700 ALL", image: "https://cdn11.bigcommerce.com/s-mjqaafwwmi/images/stencil/1280x1280/products/29737/17899/WhatsApp_Image_2022-05-26_at_12.50.35_PM__22846.1688373262.jpg?c=1" },
    { title: "Doreza Everlast", price: "Cmimi: 3500 ALL", image: "https://dyqan.taxi/wp-content/uploads/2021/04/doreza-boksi-everlast-online-ne-dyqan-taxi-1000x1000-1000x1000.jpg.webp"},
    { title: "LLastik Dobesimi", price: "Cmimi: 1300 ALL", image: "https://cdn11.bigcommerce.com/s-mjqaafwwmi/images/stencil/640w/products/29709/14408/5__70603.1671619830.PNG?c=1"},
    { title: "Fasho Boksi", price: "Cmimi: 1300 ALL", image: "https://www.megateksa-ks.com/media/catalog/product/cache/fbb2dc43818756b056bd8caf729c2982/5/0/50815_1.jpg"},
    { title: "Litar Kercimi", price: "Cmimi: 1800 ALL", image: "https://cdn11.bigcommerce.com/s-mjqaafwwmi/images/stencil/1280x1280/products/27169/6803/6000200552275__34746.1687778624.jpg?c=1"},
    { title: "Korse dobesimi", price: "Cmimi: 1600 ALL", image: "https://dyqan.taxi/wp-content/uploads/2019/08/korse-hot-shapers-brez-blei-dyqan-taxi-online-slim-body-dobesimi-1080x1080.jpg"},

  ];

  // const slides = [
  //   { title: "Dumbell", price: "from: 100$", image: handgrip },
  //   { title: "Dumbell", price: "from: 100$", image: handgrips },

    // { title: "Dumbell", image: require('./assets/dumbell2.jpg') },
    // { title: "Dumbello", image: require('./assets/dumbello.jpg') },
    // { title: "Dumbell", image: require('./assets/dumbell3.jpg') },
    // { title: "Dumbell", price: "from: 100$", image: require('./assets/dumbell4.jpg') },
    // { title: "Dumbell", image: require('./assets/dumbell5.jpg') },
  // ];
  

  return (
    <div className="carousel" id='bestseller'>
      <h2 id='doni' style={{ marginBottom: '45px', textAlign: "left", marginLeft: '60px' }}><span style={{fontSize: '30px', color: 'red'}}>BestSeller</span> <br />Produktet me te shitura</h2>

      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div className="box" key={index}>
            <h3>{slide.title}</h3>
            {/* {slide.price && <h4 style={{ color: "black" }}>{slide.price}</h4>} */}
            <img className="image" src={slide.image} alt={slide.title} />
            {slide.price && <h4 style={{ color: "black" }}>{slide.price}</h4>}
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Crs;
