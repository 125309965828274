import React from "react";
import Slider from "react-slick";
import { Container, Row, Col } from "reactstrap";

import gira1 from "../assets/gira1.png";
// import gira1 from "../../../assets/images/ava-2.jpg";
// import gira1 from "../../../assets/images/ava-3.jpg";
// import d19 from "../assets/d19.png";
import "../components/slider.css";

import naimi1 from '../assets/naimi1.png'
// import donaldi from '../assets/donaldi.png'

const TestimonialSlider = () => {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    swipeToSlide: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section id="historia">
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="testimonial " style={{marginTop: '25px'}}>
              <h2 className="testimonial__subtitle mb-4" style={{textAlign: 'center'}} >Deshmite e bleresve</h2>
              <h4>Historia Jone</h4>
              <p>                Dy miq, Naimi dhe Donaldi, ndezën pasionin e
                tyre për sportin tre vjet më parë duke krijuar një faqe në
                Instagram kushtuar shitjes së pajisjeve sportive me cilësi të
                lartë. 
                {/* Pavarësisht përballjes me sfidat, ata pa u lodhur kuruan
                një përzgjedhje të produkteve të nivelit të lartë, duke i dhënë
                përparësi cilësisë dhe kënaqësisë së klientit. Udhëtimi i tyre
                përfshinte seanca strategjike gjatë natës, krijimin e
                përmbajtjes dhe ndërtimin e lidhjeve me furnitorët që ndanë
                angazhimin e tyre për përsosmëri. */}
                 Ndërsa faqja e tyre në
                Instagram @_pajisjesportive lulëzoi, ajo u bë një qendër e besuar e komunitetit
                për entuziastët e sportit, duke ofruar produkte inovative dhe cilesore.
                Fjala u përhap me shpejtësi
                dhe sot, faqja e Naimit dhe Donaldit qëndron si ofruesi kryesor i
                pajisjeve sportive në vendin e tyre. 
                {/* Ajo që nisi si një
                sipërmarrje e vogël është shndërruar në një fener frymëzimi,
                duke festuar gëzimin e sportit dhe një stil jete aktiv.  */}

                {/* Historia
                e tyre e suksesit është më shumë se thjesht një triumf biznesi;
                është një dëshmi e fuqisë së pasionit, këmbënguljes dhe ndikimit
                të thellë që një komunitet i ndërtuar mbi vlerat e përbashkëta
                mund të ketë.  */}

                {/* Faqja në Instagram e Alex dhe Max nuk është
                thjesht një treg, por një ekosistem i gjallë ku njerëzit
                mblidhen së bashku për të festuar gëzimin e lëvizjes dhe sportet
                e miqësisë.  */}
                Në çdo produkt të dërguar, në çdo postim të
                shpërndarë, ka një histori të dy miqve që e kthyen ëndrrën e
                tyre në realitet, duke transformuar peisazhin sportiv të vendit
                të tyre. 
                {/* Udhëtimi i tyre shërben si një kujtesë se me përkushtim
                dhe një dashuri të vërtetë për atë që bëni, ju jo vetëm që mund
                të krijoni një biznes të suksesshëm, por edhe të kultivoni një
                komunitet të lulëzuar dhe të gjallë. */}</p>
              <h2 className="testimonial__title mb-4">
               Cfare mendojne <span>Klientet</span> tane
              </h2>
              <p className="testimonial__desc">

              </p>

              <Slider {...settings}>
                <div>
                  <p className="review__text">
                  Cilësi e nivelit të lartë!" Kohët e fundit bleva një grup peshash
                    dhe një set llastiqesh fitnesi nga kjo faqe, dhe duhet të them, jam
                    i impresionuar tërësisht.
                  </p>
                  <div className=" slider__content d-flex align-items-center gap-3 ">
                    {/* <img src={gira1} alt="avatar" className=" rounded" /> */}
                    <h6>Denis. K</h6>
                  </div>
                </div>

                {/* <div>
                  <p className="review__text">
                  The gear is durable, exactly as
                    described, and has taken my workouts to a whole new level!
                  </p>
                  <div className=" slider__content d-flex align-items-center gap-3 ">
                    <img src={gira1} alt="avatar" className=" rounded" />
                    <h6>Jhon Doe</h6>
                  </div>
                </div> */}


                <div>
                  <p className="review__text">
                  Ingranazhi i bicikletes është shume i qëndrueshëm . Pres te blej serish!
                  </p>
                  <div className=" slider__content d-flex align-items-center gap-3 ">
                    {/* <img src={gira1} alt="avatar" className=" rounded" /> */}
                    <h6>Marius. M</h6>
                  </div>
                </div>


                <div>
                  <p className="review__text">
                  "Shërbim i jashtëzakonshëm dhe dërgesë e shpejtë"                  </p>
                  <div className=" slider__content d-flex align-items-center gap-3 ">
                    {/* <img src={gira1} alt="avatar" className=" rounded" /> */}
                    <h6>Erdit. Sh</h6>
                  </div>
                </div>




                <div>
                  <p className="review__text">
                  “Impresionuese" Shumëllojshmëria e produkteve në dispozicion është mahnitëse!
                  </p>
                  <div className=" slider__content d-flex align-items-center gap-3 ">
                    {/* <img src={gira1} alt="avatar" className=" rounded" /> */}
                    <h6>Dea. G</h6>
                  </div>
                </div>



                <div>
                  <p className="review__text">
                  Kam blere pajisje sportive në internet prej vitesh, por kjo faqe
                    ofron disa nga çmimet më të mira pa bërë kompromis cilësisë.
                  </p>
                  <div className=" slider__content d-flex align-items-center gap-3 ">
                    {/* <img src={gira1} alt="avatar" className=" rounded" /> */}
                    <h6>Klea. Q</h6>
                  </div>
                </div>




                <div>
                  <p className="review__text">
                  Gjeta gjithçka që më duhej
                    tapetat e mi të jogës dhe më shumë.
                  </p>
                  <div className=" slider__content d-flex align-items-center gap-3 ">
                    {/* <img src={gira1} alt="avatar" className=" rounded" /> */}
                    <h6>Andiola. M</h6>
                  </div>
                </div>





                <div>
                  <p className="review__text">
                  Nuk mund te zhgenjehesh nga kjo faqe. Blerja ime e fundit
                    ishte një helmete çiklizmi dhe duhet ta permend qe ishte shume cilesore.
                  </p>
                  <div className=" slider__content d-flex align-items-center gap-3 ">
                    {/* <img src={gira1} alt="avatar" className=" rounded" /> */}
                    <h6>Andi. T</h6>
                  </div>
                </div>





                <div>
                  <p className="review__text">
                   Tapeti i jogës që bleva është
                    jo vetëm i rehatshme, por ka një stabilitet të shkëlqyeshëm.
                  </p>
                  <div className=" slider__content d-flex align-items-center gap-3 ">
                    {/* <img src={gira1} alt="avatar" className=" rounded" /> */}
                    <h6>Fatjon .P</h6>
                  </div>
                </div>




                {/* <div>
                  <p className="review__text">
                  The gear is durable, exactly as
                    described, and has taken my workouts to a whole new level!
                  </p>
                  <div className=" slider__content d-flex align-items-center gap-3 ">
                    // <img src={gira1} alt="avatar" className=" rounded" />
                    <h6>Jhon Doe</h6>
                  </div>
                </div> */}




{/* 
                <div>
                  <p className="review__text">
                  The gear is durable, exactly as
                    described, and has taken my workouts to a whole new level!
                  </p>
                  <div className=" slider__content d-flex align-items-center gap-3 ">
                    <img src={gira1} alt="avatar" className=" rounded" />
                    <h6>Jhon Doe</h6>
                  </div>
                </div> */}







                <div>
                  <p className="review__text">
                  Nuk mund ta
                    rekomandoj mjaftueshëm këtë platformë. Cilësia, besueshmëria,
                    dhe shpejtësia e shërbimit janë të pakrahasueshme.
                  </p>
                  <div className=" slider__content d-flex align-items-center gap-3 ">
                    <img src={gira1} alt="avatar" className=" rounded" />
                    <h6>Jhon Doe</h6>
                  </div>
                </div>









                {/* <div>
                  <p className="review__text">
                  "Përvoja fantastike në internet" Lundrimi në faqen e internetit ishte një
                    fllad! Paraqitja është intuitive dhe gjetja e asaj që më duhej
                    ishte pa probleme.
                  </p>
                  <div className="slider__content d-flex align-items-center gap-3 ">
                    <img src={gira1} alt="avatar" className=" rounded" />
                    <h6>Mitchell Marsh</h6>
                  </div>
                </div> */}





                {/* <div>
                  <p className="review__text">
                    1. Great Selection and Quality: "I am thrilled with the
                    extensive range of sports equipment available on this page!
                    The quality is exceptional, and the diverse selection
                    ensures that I can find exactly what I need for my fitness
                    journey. Five stars for the fantastic variety and top-notch
                    quality!" 2. Excellent Customer Service: "The customer
                    service here is unparalleled. I had a few questions about a
                    specific product, and the team was quick to respond and
                    incredibly helpful. Their dedication to customer
                    satisfaction truly sets this page apart. A definite 5-star
                    experience!" 3. Fast and Reliable Shipping: "I was
                    pleasantly surprised by the speedy delivery of my sports
                    equipment. The items arrived in perfect condition, and the
                    shipping process was hassle-free. This page's commitment to
                    efficient service earns it a solid 5 stars!" 4. Competitive
                    Prices: "Not only does this page offer a fantastic array of
                    sports gear, but the prices are also very competitive. I was
                    able to get high-quality equipment without breaking the
                    bank. Five stars for delivering excellent value for money!"
                    5. User-Friendly Website: "Navigating through the website
                    was a breeze. The layout is intuitive, making it easy to
                    find and purchase the sports equipment I needed. The
                    user-friendly design earns this page a well-deserved 5-star
                    rating!" 6. Reliable Product Information: "The detailed
                    product descriptions and specifications provided on this
                    page are immensely helpful. It instills confidence in my
                    purchases, knowing exactly what to expect. Accurate
                    information and transparency deserve a 5-star review!" 7.
                    Impressive Durability: "I purchased a set of sports
                    equipment, and the durability has exceeded my expectations.
                    The items have stood up to rigorous use, showcasing the
                    excellent quality of the products offered on this page. Five
                    stars for durability and longevity!" 8. Hassle-Free Returns:
                    "I had to make a return due to a sizing issue, and the
                    return process was smooth and hassle-free. The customer
                    service team was accommodating, making the overall shopping
                    experience incredibly pleasant. Five stars for their
                    customer-centric approach!" 9. Regular Updates and
                    Promotions: "I appreciate the regular updates on new
                    arrivals and promotions. It keeps me informed about the
                    latest products and offers, making it even more enticing to
                    shop here. Consistent communication and great deals deserve
                    a 5-star rating!" 10. Overall Satisfaction: "In summary, my
                    experience with this sports equipment page has been
                    exceptional. From the vast selection to the impeccable
                    customer service, every aspect of my interaction with this
                    page has been positive. I'm a satisfied customer and happily
                    award it a well-deserved 5 stars!"
                  </p>
                  <div className="slider__content d-flex align-items-center gap-3 ">
                    <img src={gira1} alt="avatar" className=" rounded" />
                    <h6>Steven Crock</h6>
                  </div>
                </div> */}


              </Slider>
            </div>
          </Col>

          <Col lg="6" md="6">
            <img src={naimi1} alt="testimonial-img" className="w-100" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TestimonialSlider;
