// // import React from 'react';
// // // import ReactDOM from 'react-dom/client';
// // import './index.css';
// // import App from './App';
// // import reportWebVitals from './reportWebVitals';
// // import ReactDOM from 'react-dom';

// // import 'bootstrap/dist/css/bootstrap.min.css';

// // import store from './components/store';
// // import { Provider } from "react-redux";

// // import { BrowserRouter as Router } from "react-router-dom";


// // ReactDOM.render(
// //   <React.StrictMode>
// //     <Router>
// //       <Provider store={store}>
// //         <App />
// //       </Provider>
// //     </Router>
// //   </React.StrictMode>,
// //   document.getElementById("root")
// // );


// // reportWebVitals();


// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// // import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import store from './components/store';
// import { Provider } from "react-redux";
// import { BrowserRouter as Router } from "react-router-dom";



// ReactDOM.render(
//   <React.StrictMode>
//     <Router>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </Router>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

import { createRoot } from 'react-dom/client';
import React from 'react';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import store from './components/store';
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </React.StrictMode>
);
